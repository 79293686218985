
import http from "@/services/http";

const get = (params) => http.get(`billets`, { params: params })
const changeDate = (id, params) => http.post(`billets/${id}/change-date`, params);
const markAsPaid = (params) => http.post(`billets/mark-as-paid`, params);
const getBillet = (id) => http.get(`billets/${id}/get`);

export default {
  get,
  changeDate,
  markAsPaid,
  getBillet
}
