<template>
  <div>
    <div v-if="alertMessage" class="col-12">
      <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
    </div>

    <div class="page-body">
      <div class="card">
        <div class="card-body p-3 p-lg-5">
          <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
            <b-form @submit.stop.prevent="handleSubmit(policy)" class="form" autocomplete="off">
              <div class="row">
                <div class="col-12 mb-3">
                  <h6 class="form-subheader"><span>Tipo do seguro</span></h6>
                </div>
                <div v-bind:class="getClassCategorySelects()">
                  <label class="dbo-ui" for="">Tipo seguro</label>
                  <validation-provider name="tipo de seguro" rules="required">
                    <div slot-scope="ProviderProps">
                      <select :class="{ 'is-invalid': ProviderProps.errors[0] }" v-model="dataForm.insurance_type_id"
                        class="form-control">
                        <option disabled="disabled" :value="null">
                          Selecione...
                        </option>
                        <option v-for="objType in insuranceTypes" :key="objType.id" :value="objType.id">
                          {{ objType.name }}
                        </option>
                      </select>
                      <p class="invalid-feedback-custom">
                        {{ ProviderProps.errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div v-bind:class="getClassCategorySelects()">
                  <label class="dbo-ui" for="">Subtipo / Modalidade</label>
                  <validation-provider name="subtipo de seguro">
                    <div slot-scope="ProviderProps">
                      <select :class="{ 'is-invalid': ProviderProps.errors[0] }"
                        v-model="dataForm.insurance_category_id" class="form-control">
                        <option disabled="disabled" :value="null">
                          Selecione...
                        </option>
                        <option v-for="objCategory in insuranceCategories" :key="objCategory.id"
                          :value="objCategory.id">
                          {{ objCategory.tecnic_name || objCategory.name }}
                        </option>
                      </select>
                      <p class="invalid-feedback-custom">
                        {{ ProviderProps.errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>

                <div v-if="checkJudicialRecursalFlow" v-bind:class="getClassCategorySelects()">
                  <label class="dbo-ui" for="">Tipo de Recurso</label>
                  <validation-provider name="tipo de recurso" rules="required">
                    <div slot-scope="ProviderProps">
                      <select class="form-control" :class="{ 'is-invalid': ProviderProps.errors[0] }"
                        v-model="dataForm.resource_type">
                        <option disabled :value="null">Escolha um Tipo...</option>
                        <option v-for="resource in resources" :key="resource.id" :value="resource.id">
                          {{ resource.name }}
                        </option>
                      </select>
                      <p class="invalid-feedback-custom">
                        {{ ProviderProps.errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>

                <div class="col-12 mb-3">
                  <h5 class="form-subheader">
                    <span>Dados de contrato</span>
                  </h5>
                </div>
                <div class="col-md-6 mb-3">
                  <label class="dbo-ui" for="">Seguradora</label>
                  <validation-provider name="seguradora" rules="required" vid="insurer_selected" ref="insurer_selected">
                    <div slot-scope="ProviderProps">
                      <select :class="{ 'is-invalid': ProviderProps.errors[0] }" v-model="dataForm.insurer_selected"
                        class="form-control">
                        <option disabled="disabled" :value="null">
                          Selecione...
                        </option>
                        <option v-for="(name, identifier) in insurers" :key="identifier" :value="identifier">
                          {{ name }}
                        </option>
                      </select>
                      <p class="invalid-feedback-custom">
                        {{ ProviderProps.errors[0] }}
                      </p>
                    </div>
                  </validation-provider>
                </div>
                <div v-if="dataForm.insurer_selected == 'other'" class="col-md-6 mb-3">
                  <validation-provider name="outra seguradora"
                    :rules="`${dataForm.insurer_selected == 'other' ? 'required|max:100' : ''}`"
                    v-slot="validationContext" vid="other_insurer">
                    <label class="dbo-ui" for="">Nome da Seguradora</label>
                    <b-form-input :focus="focus" v-model="dataForm.other_insurer"
                      :state="getValidationState(validationContext)"></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-md-12 mb-3">
                  <validation-provider name="corretora" rules="max:191" v-slot="validationContext"
                    vid="insurence_broker">
                    <label class="dbo-ui" for="">Corretora</label>
                    <b-form-input :focus="focus" v-model="dataForm.insurance_broker"
                      :state="getValidationState(validationContext)" class="py-4 col-md-6"></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <h6 class="form-subheader">
                        <span>Dados do tomador</span>
                      </h6>
                    </div>

                    <div class="col-12 mb-3">
                      <validation-provider name="documento" rules="required" v-slot="validationContext"
                        vid="policy_holder_document">
                        <label class="dbo-ui" for="">CNPJ / CPF</label>
                        <b-form-input v-model="dataForm.policy_holder_document"
                          :state="getValidationState(validationContext)" v-mask="documentMask"></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-12 mb-3">
                      <validation-provider name="nome" rules="required|max:191" v-slot="validationContext"
                        vid="policy_holder_name">
                        <label class="dbo-ui" for="">Razão social / Nome</label>
                        <b-form-input :focus="focus" v-model="dataForm.policy_holder_name"
                          :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>


                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="row">
                    <div class="col-12 mb-3">
                      <h6 class="form-subheader">
                        <span>Dados do segurado</span>
                      </h6>
                    </div>
                    <div class="col-12 mb-3">
                      <validation-provider name="documento" rules="required" v-slot="validationContext"
                        vid="insured_document">
                        <label class="dbo-ui" for="">CNPJ / CPF</label>
                        <b-form-input v-model="dataForm.insured_document" :state="getValidationState(validationContext)"
                          v-mask="documentMask"></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <div class="col-md-12 mb-3">
                      <validation-provider name="nome" rules="required|max:191" v-slot="validationContext"
                        vid="insured_name">
                        <label class="dbo-ui" for="">Razão social / Nome</label>
                        <b-form-input :focus="focus" v-model="dataForm.insured_name"
                          :state="getValidationState(validationContext)"></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <h5 class="form-subheader">
                    <span>Dados da apólice</span>
                  </h5>
                </div>
                <div class="col-md-6 mb-3">
                  <validation-provider name="external_number" v-slot="validationContext" vid="external_number">
                    <label class="dbo-ui" for="">Número Externo</label>
                    <b-form-input v-model="dataForm.external_number"
                      :state="getValidationState(validationContext)"></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3">
                  <validation-provider name="contract_number" v-slot="validationContext" vid="contract_number">
                    <label class="dbo-ui" for="">Número do Contrato/Processo</label>
                    <b-form-input v-model="dataForm.contract_number"
                      :state="getValidationState(validationContext)"></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3">
                  <validation-provider name="valor de cobertura" rules="required" v-slot="validationContext">
                    <label class="dbo-ui" for="">Valor da cobertura</label>
                    <money v-bind="money" v-model="dataForm.coverage_value"
                      :state="getValidationState(validationContext)" @keypress.native="getValidationLess($event)"
                      class="form-control" />
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-md-6 mb-3">
                  <validation-provider name="prêmio" rules="required" v-slot="validationContext"
                    vid="insurance_premium">
                    <label class="dbo-ui" for="">Prêmio</label>
                    <money v-bind="money" v-model="dataForm.insurance_premium"
                      :state="getValidationState(validationContext)" @keypress.native="getValidationLess($event)"
                      class="form-control" />
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-md-4 mb-3">
                  <validation-provider name="data início" rules="required" v-slot="validationContext"
                    vid="validity_start">
                    <label class="dbo-ui">Data de início</label>
                    <b-form-input v-model="dataForm.validity_start" type="date"
                      :state="getValidationState(validationContext)" placeholder=""></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-md-4 mb-3">
                  <validation-provider name="quantidade de dias" rules="required" v-slot="validationContext">
                    <label class="dbo-ui">Vencimento</label>
                    <b-form-input v-model="dataForm.quantity_days" type="number"
                      :state="getValidationState(validationContext)" @keypress.native="getValidationLess($event)"
                      placeholder=""></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>
                <div class="col-md-4 mb-3">
                  <validation-provider name="data final" rules="required" v-slot="validationContext"
                    vid="validity_expiration">
                    <label class="dbo-ui">Data de vencimento</label>
                    <b-form-input v-model="dataForm.validity_expiration" type="date"
                      :state="getValidationState(validationContext)" placeholder=""></b-form-input>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                      }}</b-form-invalid-feedback>
                  </validation-provider>
                </div>

                <div class="col-12 col-lg-6 mb-3">
                  <label class="dbo-ui">Apólice</label>
                  <b-form-checkbox v-model="dataForm.renewable" id="checkbox-1" name="checkbox-1">
                    Renovável
                  </b-form-checkbox>
                </div>

                <div class="col-12 mb-3">
                  <upload-data ref="upload" :files="files" :url="urlUpload" :forceRedirect="true"
                    @deleteFile="deleteFile" @upload-finished="redirect(routeRedirect)" />
                </div>

                <div class="col-12 text-right">
                  <button :disabled="invalid || checkValues" class="button btn btn-primary">
                    <b-spinner v-if="loading" small></b-spinner>
                    <i v-else class="fa fa-check mr-1"></i> Salvar
                  </button>
                </div>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import enumInsuranceCategoryTypes from "@/enums/insuranceCategoryTypes";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { UploadData } from '@/components';
import policyService from "@/services/policy";
import commonsService from "@/services/commons";
import commomsHelper from "@/mixins/commomsHelper";
import dateFormatter from "@/mixins/dateFormatter";
import { mask } from "vue-the-mask";

export default {
  name: "AutomaticPolicyRenewal",
  mixins: [dateFormatter, commomsHelper],
  components: {
    ValidationObserver,
    ValidationProvider,
    UploadData
  },
  directives: {
    mask,
  },
  props: {
    policyId: {
      type: String,
    },
  },
  data: () => ({
    dataForm: {
      alertMessage: '',
      insurance_type_id: null,
      insurance_category_id: null,
      insurer_id: null,
      policy_holder_document: '',
      policy_holder_name: null
    },
    insuranceTypes: [],
    insurers: [],
    files: [],
    urlUpload: '',
    routeRedirect: 'details-policy',
    insuranceCategories: [],
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: " ",
      precision: 2,
      masked: false,
    },
    loading: false,
    focus: false,
    validate: false,
    copyDataTaker: false,
    resources: [],
    newPolicyId: null,
  }),
  computed: {
    quantityDays() {
      return this.dataForm.quantity_days;
    },
    validityStart() {
      return this.dataForm.validity_start;
    },
    validityExpiration() {
      return this.dataForm.validity_expiration;
    },
    insuranceType() {
      return this.dataForm.insurance_type_id;
    },
    documentMask() {
      return ["###.###.###-##", "##.###.###/####-##"];
    },
    showDestroy() {
      return !this.policyId;
    },
    showCheckboxCopyData() {
      return this.dataForm.policy_holder_document && this.dataForm.policy_holder_name
    },
    action() {
      return this.policyId ? "Editar" : "Nova";
    },
    dataFormToSave() {
      return {
        ...this.dataForm,
        insurer_selected: this.dataForm.insurer_selected == 'other' ? null : this.dataForm.insurer_selected,
        renovations: {
          policy_origin_id: this.policyId,
        },
      };
    },
    checkValues() {
      return !this.dataForm.policy_holder_document;
    },
    checkJudicialRecursalFlow() {
      if (!this.dataForm.insurance_category_id || this.dataForm.quotation_id) {
        return false;
      }
      return this.allowedResources.indexOf(this.dataForm.insurance_category_id) != -1
    },
    allowedResources() {
      return [enumInsuranceCategoryTypes.MAKE_A_LABOR_APPEAL_DEPOSIT,
      enumInsuranceCategoryTypes.REPLACEMENT_OF_APPEAL_DEPOSIT]
    },
  },
  created() {
    if (this.policyId) {
      this.getPolicy(this.policyId);
      this.findPolicyFiles(this.policyId);
    }
    this.getInsuranceTypes();
    this.getInsurers();
    this.getResources();
  },
  methods: {
    redirect(routeName) {
      this.$router.push({
        name: routeName,
        params: { policyId: this.newPolicyId }
      });
    },
    findPolicyFiles(uid) {
      policyService
        .getPolicyFiles(uid)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.files = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os arquivos da cotação.");
        });
    },
    getInsuranceTypes() {
      commonsService
        .insuranceTypes()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.insuranceTypes = response.data.sort((t1, t2) => t1.name < t2.name ? -1 : 1);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os tipos de seguro.");
        });
    },
    getInsurers() {
      commonsService
        .insurers()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.insurers = { ...response.data, other: "Outra" };
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as seguradoras.");
        });
    },
    getInsuranceCategories(id) {
      commonsService
        .insuranceCategories(id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.insuranceCategories = this.formatInsuranceCategories(response);
        })
        .catch(() => {
          this.showError(
            "Não foi possível recuperar os sub-tipos do respectivo seguro."
          );
        });
    },
    getResources() {
      commonsService
        .resourceTypes()
        .then((response) => {
          this.resources = response.data;
        })
        .catch(() => {
          this.$parent.showError("Não foi possível recuperar os recursos.");
        });
    },
    deleteFile(fileId) {
      policyService
        .deletePolicyFile(this.policyId, fileId)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível deletar o arquivo.");
        });
    },
    getValidationLess(event) {
      return event.key === "-" ? event.preventDefault() : null;
    },
    getPolicy(uid) {
      policyService
        .getByUID(uid)
        .then(async (response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          await this.fillFields(response.data);
          this.$refs.observer.validate();
        })
        .catch(() => {
          this.showError("Não foi possível recuperar a apólice.");
        });
    },
    formatInsuranceCategories(response) {
      var categories = response.data;
      var categoriesWithSubCategories = categories.filter(
        (e) => e.sub_categories
      );
      categoriesWithSubCategories.forEach((e) => {
        e.sub_categories.forEach((element) => {
          element.parent_insurance_category_id == enumInsuranceCategoryTypes.I_HAVE_A_LAWSUIT ?
            categories.push(element) :
            null;
        });
      });
      return categories.filter((e) => e.id !== enumInsuranceCategoryTypes.I_HAVE_A_LAWSUIT);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    storePolicy() {
      return policyService.store(this.dataFormToSave);
    },
    policy() {
      this.resetAlert();
      let savePromise = this.storePolicy();
      this.loading = true;
      savePromise
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.newPolicyId = response.data.id;
          this.urlUpload = `/api/v1/policies/${response.data.id}/files`
          this.$nextTick(() => {
            if (this.$refs.upload.$refs.dropzone.getAcceptedFiles().length > 0) {
              this.$refs.upload.$refs.dropzone.processQueue()
              return
            }
            this.redirect(this.routeRedirect)
          });
        })
        .catch(error => {
          if (error.response.data.errors) {
            this.$refs.observer.setErrors(error.response.data.errors);
          }
          this.showError("Não foi possível executar a apólice.");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async fillFields(element) {
      this.dataForm.insurance_type_id = element.insurance_type_id;
      this.dataForm.insurance_category_id = element.insurance_category_id;
      this.dataForm.insurance_broker = element.insurance_broker;
      this.dataForm.policy_holder_document = element.policy_holder_document;
      this.dataForm.policy_holder_name = element.policy_holder_name;
      this.dataForm.insured_name = element.insured_name;
      this.dataForm.insured_document = element.insured_document;
      this.dataForm.coverage_value = element.coverage_value;
      this.dataForm.insurance_premium = element.insurance_premium;
      this.dataForm.insurer_selected = element.insurer_selected == null ? 'other' : element.insurer_selected;
      this.dataForm.other_insurer = element.other_insurer;
      this.dataForm.external_number = element.external_number;
      this.dataForm.contract_number = element.contract_number;
      this.dataForm.resource_type = element.resource_type;
      this.dataForm.quotation_id = element.quotation_id;

      if (element.renewable) {
        this.dataForm.renewable = true
      }
      const dataAtual = new Date().toISOString().split('T')[0];
      this.$set(this.dataForm, "validity_start", dataAtual);
      this.$set(
        this.dataForm,
        "quantity_days",
        this.calculateQuantityDays(
          element.validity_start,
          element.validity_expiration
        )
      );
      this.setFocus();
    },
    setFocus() {
      this.focus = true;
      this.focus = false;
    },
    calculateDateFinal(days, dateInitial) {
      var d = new Date(dateInitial);
      d.setDate(d.getDate() + 1);
      var dateFormat = new Date(d.getTime() + days * 24 * 60 * 60 * 1000);
      return this.formatDateISO(dateFormat);
    },
    switchName(name, field) {
      switch (field) {
        case 'holderName':
          this.$set(this.dataForm, 'policy_holder_name', name);
          break;
        case 'insuredName':
          this.$set(this.dataForm, 'insured_name', name);
          break;
      }
    },
    back() {
      this.$router.push({
        name: this.routeRedirect,
        query: { filter: true },
      });
    },
    getClassCategorySelects() {
      return {
        'col-md-4 mb-3': this.checkJudicialRecursalFlow,
        'col-md-6 mb-3': !this.checkJudicialRecursalFlow
      };
    }
  },
  watch: {
    quantityDays(value) {
      if (this.dataForm.validity_start) {
        var expiration = this.calculateDateFinal(
          value,
          this.dataForm.validity_start
        );
        this.$set(this.dataForm, 'validity_expiration', expiration);
      }
    },
    validityStart(value) {
      if (this.dataForm.quantity_days) {
        var expiration = this.calculateDateFinal(
          this.dataForm.quantity_days,
          value
        );
        this.$set(this.dataForm, 'validity_expiration', expiration);
      }
    },
    validityExpiration(value) {
      if (this.dataForm.validity_start) {
        var quantity_days = this.calculateQuantityDays(
          this.dataForm.validity_start,
          value
        );
        this.$set(this.dataForm, 'quantity_days', quantity_days);
      }
    },
    insuranceType(value) {
      this.getInsuranceCategories(value);
    },
    copyDataTaker(value) {
      if (value) {
        this.dataForm.insured_document = this.dataForm.policy_holder_document
        this.dataForm.insured_name = this.dataForm.policy_holder_name
        return
      }
      this.dataForm.insured_document = null
      this.dataForm.insured_name = null
    }
  },
};
</script>

<style></style>
