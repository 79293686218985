<template>
  <div>
    <div
      class="overlay d-flex align-items-center justify-content-center"
      v-if="loading"
    >
      <b-spinner class="text-primary"></b-spinner>
    </div>
    <div class="row">
      <div class="col-12 mb-3">
        <h5>
          <div class="form-subheader"><span>Dados Gerais</span></div>
        </h5>
      </div>

      <div class="col-12 col-lg-4 mb-3">
        <label class="dbo-ui" for="">Número da apólice</label>
        <p>{{ policy.number }}</p>
      </div>

      <div class="col-12 col-lg-3 mb-3">
        <label class="dbo-ui" for="">Seguradora</label>
        <p>{{ policy.insurer ? policy.insurer.name : "---" }}</p>
      </div>

      <div class="col-12 col-lg-3 mb-3" v-if="policy.external_number">
        <label class="dbo-ui" for="">Número Externo</label>
        <p>{{ policy.external_number }}</p>
      </div>

      <div class="col-12 col-lg-2 mb-3">
        <label class="dbo-ui" for="">Criado em</label>
        <p>{{ formatDateLocal(policy.created_at) }}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-lg-4 mb-3 order-1">
        <label class="dbo-ui" for="">Tipo</label>
        <p>
          {{
            getEnumKeyByEnumValue(
              translateInsuranceTypes,
              policy.insurance_type_id
            )
          }}
        </p>
      </div>

      <div class="col-12 col-lg-3 mb-3 order-2">
        <label class="dbo-ui" for="">Subtipo</label>
        <p>
          {{
            policy.insurance_category ? policy.insurance_category.name : "---"
          }}
        </p>
      </div>

      <div class="col-12 col-lg-3 mb-3 order-3">
        <label class="dbo-ui" for="">Nº do edital/contrato/pedido </label>
        <p>
          {{
            policy.quotation && policy.quotation.guarantee
              ? policy.quotation.guarantee.contract_number
              : (policy.contract_number ? policy.contract_number : "---")
          }}
        </p>
      </div>

      <div class="col-lg-2 mb-3 order-4"
        v-if="policy.quotation">
        <label class="dbo-ui" for="">Cotação</label>
          <p>
            {{ policy.quotation.number }}
            <i
              v-b-tooltip.hover title="Visualizar Cotação"
              @click="redirect('details-quotation', { quotationId: policy.quotation.id })"
              class="fas fa-eye icon-btn mx-1 small align-self-center"></i>
          </p>
      </div>

      <div class="col-12 col-lg-4 mb-3 order-6">
        <label class="dbo-ui" for="">CPF/CNPJ Tomador</label>
        <p>{{ policy.policy_holder_document }}</p>
      </div>

      <div class="col-12 col-lg-3 mb-3 order-7">
        <label class="dbo-ui" for="">Tomador</label>
        <p>{{ policy.policy_holder_name }}</p>
      </div>

      <div class="col-12 col-lg-3 mb-3 order-8">
        <label class="dbo-ui" for="">Nome do usuário</label>
        <p>
          {{ policy.user ? policy.user.name : ' Indisponível '}}
          <button
            v-if="isMaster()"
            size="sm"
            variant="primary"
            v-b-modal.show-modal-alt
            class="btn btn-outline-primary ml-3"
          >
            Alterar
          </button>
        </p>
      </div>

      <div class="col-12 col-lg-2 mb-3"
        :class="policy.quotation ? 'order-9' : 'order-5'">
        <label class="dbo-ui" for="">status</label>
        <span
          :class="classStatusQuotation(IsInRenovation(policy) ?? policy.status) + ' d-block col-md-7'"
        >
          {{
            IsInRenovation(policy) == 15 || IsInRenovation(policy) == 18  ?
            in_renovation :
            getEnumKeyByEnumValue(translateStatusQuotationTypes, policy.status)
          }}
        </span>
      </div>

    </div>

    <div class="modal">
      <b-modal
        id="show-modal-alt"
        ref="showModalAlt"
        size="lg"
        class="mt-4"
        hide-footer
        centered
      >
        <br />
        <template #modal-title> Alterar Usuário </template>
        <div class="input-group mb-4">
          <b-form-input
            @keyup="searchUser()"
            placeholder="Digite sua busca..."
            v-model="filter"
          ></b-form-input>
          <div class="input-group-append">
            <button class="btn btn-primary">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
        <div class="overlay_modal" v-if="loadingModal">
          <b-spinner class="text-primary"></b-spinner>
        </div>
        <div class="mb-3 list">
          <div
            v-for="user in users"
            :key="user.id"
            :value="user.id"
            class="h-100 text-left d-flex align-items-center px-3 py-2"
          >
            <div
              v-if="user.id == user_selected"
              class="card shadow border-1 h-100 w-100 shadow-primary border-primary"
            >
              <div class="h-100 text-left d-flex align-items-center px-3 py-2">
                <h6 class="mb-1 pr-3">{{ user.name }}</h6>
                <span class="font-14 text-primary"
                  >{{ user.email }} - {{ user.document }}</span
                >
                <button
                  class="ml-auto button btn py-2 nowrap btn-outline-primary"
                >
                  <i class="fa fa-check text-primary"></i>
                  <span class="d-none d-lg-inline"> Selecionar</span>
                </button>
              </div>
            </div>

            <div v-else class="card shadow border-1 h-100 w-100">
              <div class="h-100 text-left d-flex align-items-center px-3 py-2">
                <h6 class="mb-1 pr-3">{{ user.name }}</h6>
                <span class="font-14 text-primary"
                  >{{ user.email }} - {{ user.document }}</span
                >
                <button
                  class="ml-auto button btn py-2 nowrap btn-outline-primary"
                  @click="selectUser(user.id)"
                >
                  <i class="fa fa-check text-primary"></i>
                  <span class="d-none d-lg-inline"> Selecionar</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import userService from "@/services/user";
import policyService from "@/services/policy";
import commomsHelper from "@/mixins/commomsHelper";
import dateFormatter from "@/mixins/dateFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import authenticationService from "@/services/authentication";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";

export default {
  name: "GeneralData",
  emits: ["getPolicy"],
  props: {
    policy: {
      type: Object,
      required: true,
    },
  },
  mixins: [dateFormatter, commomsHelper],
  data: () => ({
    translateInsuranceTypes: translateInsuranceTypes,
    users: {},
    user_selected: null,
    translateStatusQuotationTypes: translateStatusQuotationTypes,
    filter: "",
    users_data: {},
    loading: false,
    loadingModal: false,
    in_renovation: 'Em Renovação'
  }),
  methods: {
    getUsers() {
      userService
        .users()
        .then((response) => {
          this.users = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os usuários.");
        })
        .finally(() => {
          this.users_data = this.users;
          this.loadingModal = false;
        });
    },
    updateUser() {
      this.loading = true;
      policyService
        .updateUser(this.policy.id, this.user_selected)
        .then((response) => {
          this.$refs.showModalAlt.hide();
          this.policy = response.data;
        })
        .catch(() => {
          this.showError("Não foi possível alterar o usuário.");
        })
        .finally(() => {
          this.getPolicy();
          this.loading = false;
        });
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    searchUser() {
      this.loadingModal = true;
      this.users = this.users_data;
      var usersObject = this.users.filter((user) => {
        if (
          user.document.includes(this.filter) ||
          user.email.toLowerCase().includes(this.filter.toLowerCase()) ||
          user.name.toLowerCase().includes(this.filter.toLowerCase())
        ) {
          return user;
        }
      });
      this.users = usersObject;
      this.loadingModal = false;
    },
    selectUser(user_id) {
      this.user_selected = user_id;
      this.$bvModal.hide("show-modal-alt");
      this.updateUser();
    },
    getPolicy(){
      this.$emit('getPolicy', this.getIdFromUrl());
    },
    IsInRenovation(policy){
      if(policy.renovations && policy.renovations[0]) {
        var data = policy.renovations[0];
        return (data.status_id == 15 || data.status_id == 18) ?  data.status_id : false;
      }
      return false;
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
  },
  created() {
    this.getUsers();
  },
};
</script>

<style>
</style>
