<template>
  <b-modal
    v-model="show"
    size="xl"
    :hide-cancel="true"
  >
    <template #modal-title>
      <div>
        <div>
          <h5 class="modal-title">
            Importar planilha de apólices
          </h5>
        </div>
      </div>
    </template>
    <div class="p-3 pb-0">
      <div v-if="alertMessage" class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      <UploadFile
        v-if="step == 1"
        v-model="importFile"
      />
      <PreviewPolicies
        v-if="step == 2"
        :policies="policies"
        :errors="errors"
      />
      <div v-if="step == 3">
        <div class="alert-custom alert-success-custom">Sucesso! Suas apólices foram importadas. Agora você pode fechar essa janela</div>
      </div>
      
      <div v-if="alertMessage && step == 2" class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
    </div>
    <template #modal-footer>
      <div class="d-flex flex-grow-1 justify-content-between flex-row-reverse">
        <button
          v-if="step == 2"
          @click="discardImportProcess()"
          type="button"
          class="btn btn-outline-secondary order-2"
        >
          <b-spinner v-if="loadingDiscard" small></b-spinner> Descartar
        </button>
        <button
          v-if="$can('policy-import')"
          :disabled="!importFile || errors"
          @click="next()"
          type="button"
          class="btn btn-primary order-1"
        >
          <b-spinner v-if="loading" small></b-spinner> {{ nextLabel }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import policyService from "@/services/policy";
import UploadFile from "./Steps/UploadFile.vue";
import PreviewPolicies from "./Steps/PreviewPolicies/Index.vue";

export default {
  name: 'ImportPolicies',
  components: {
    UploadFile,
    PreviewPolicies,
  },
  props: {
    value: {
      type: Boolean
    }
  },
  data() {
    return {
      importFile: null,
      loading: false,
      loadingDiscard: false,
      step: 1,
      policies: [],
      importId: null,
      errors: null
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    nextLabel() {
      const labels = {
        1: 'Prosseguir',
        2: 'Confirmar importação',
        3: 'Ok'
      }

      return labels[this.step];
    }
  },
  methods: {
    async next() {
      this.resetAlert();
      if (this.step == 1) {
        await this.sendFile();
        this.step++;
        return;
      }

      if (this.step == 2) {
        this.loading = true;
        await policyService.confirmImportPolicies(this.importId)
          .then(() => {
            this.step++;
            this.loading = false;
          })
          .catch(() => {
            this.showError("Não foi possível importar os dados.");
            this.loading = false;
          });
        return;
      }

      this.$emit('policiesImported');
      this.clearState();
      this.show = false;
    },
    async sendFile() {
      this.loading = true;
      try {

        const responseUpload = await policyService.uploadImportSpreadsheet(this.importFile);

        if (responseUpload.data.error) {
          this.showError(responseUpload.data.message);
          return;
        }

        this.importId = responseUpload.data.id;
      } catch(error) {
        this.loading = false;
        this.showError('Ocorreu um problema ao fazer upload da planilha');
        throw error;
      }

      let responseStatus = await policyService.getImportSpreadsheetPreview(this.importId);

      while (responseStatus.code == 204) {
        await this.timeout(1000);

        responseStatus = await policyService.getImportSpreadsheetPreview(this.importId);
      }

      this.loading = false;

      if (responseStatus.data.valid) {
        this.policies = responseStatus.data.policies;
        return;
      }

      this.errors = responseStatus.data.errors;
    },
    discardImportProcess() {
      this.loadingDiscard = true;
      policyService.discardImportSpreadsheet(this.importId)
        .then(() => {
          this.clearState();
        })
        .finally(() => {
          this.loadingDiscard = false;
        })
    },
    clearState() {
      this.step = 1;
      this.importFile = null;
      this.importId = null;
      this.policies = [];
      this.errors = null;
      this.resetAlert();
    },
    timeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
};
</script>

<style>
</style>
