<template>
  <div class="card-dashboard">
    <div class="py-0 d-flex justify-content-between align-items-center bg-light-dashboard accumulated-value">
      <div class="text-center w-50">
        <h5 class="center-text">Valor acumulado de prêmios no período</h5>
        <div class="font-28">
          <strong>{{ formatCurrency(accumulated_value) }}</strong>
        </div>
      </div>
      <div class="divider"></div>
      <div class="text-center mr-4 w-50">
        <div class="period-selection">
          <h3 class="period-title">Período atual</h3>
          <div class="period-buttons">
            <button class="btn btn-outline-primary" :class="{ active: selectedPeriod === 30 }" @click="setPeriod(30)" style="border-radius: 5px 0px 0px 5px;">30 dias</button>
            <button class="btn btn-outline-primary" :class="{ active: selectedPeriod === 60 }" @click="setPeriod(60)" style="border-radius: 0px;">60 dias</button>
            <button class="btn btn-outline-primary" :class="{ active: selectedPeriod === 120 }" @click="setPeriod(120)" style="border-radius: 0px 5px 5px 0px;">120 dias</button>
            <button class="btn btn-outline-secondary ml-2" @click="showModalDashboard()">
              <i class="mdi mdi-dots-vertical"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-body py-0 bg-light-dashboard">
      <div class="row text-center pb-4">
        <div class="col-lg-12 col-xl-12 col-12 border-lg-right-lg-3">
          <div class="top-premiums mt-3">
            <h3>Top 10 maiores prêmios do período</h3>
            <table class="premium-table">
              <thead class="rowgroup">
                <tr>
                  <th class="nowrap text-center">ID</th>
                  <th class="nowrap text-center">IS</th>
                  <th class="nowrap text-center">Tomador</th>
                  <th class="nowrap text-center">Prêmio</th>
                  <th class="nowrap text-center">Em Renovação</th>
                  <th class="nowrap text-center">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="policy in top_premiums" :key="policy.id">
                  <td class="nowrap text-center">{{ policy.number }}</td>
                  <td class="nowrap text-center">{{ formatCurrency(policy.coverage_value) }}</td>
                  <td class="nowrap text-center">{{ policy.policy_holder_name }}</td>
                  <td class="nowrap text-center">{{ formatCurrency(policy.insurance_premium) }}</td>
                  <td class="nowrap text-center">
                    <i :class="policy.renovation_id ? 'fa fa-check font-18 text-success me-1' : 'fa fa-clock text-orange font-16 me-1'"></i>
                  </td>
                  <td class="nowrap text-center">
                    <span
                      :class="getStatusClass(policy.status)"
                      class="status-pill-dashboard"
                      :style="{backgroundColor: cardColorsTags[policy.status]}"
                      >
                      {{ getNameColumn(policy.status) }}
                    </span>
                  </td>
                  <td @click="$can('policy-show') ? redirect('details-policy', { policyId: policy.id }) : null" class="btn-action cursor-pointer">
                    <i class="fa fa-eye"></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="show-modal-dashboard" ref="modalDashboard" size="md" class="mt-4" hide-footer centered>
      <template #modal-title>Definir Período Customizado</template>
      <div>
        <div class="row form">
          <div class="col-12">
            <label class="dbo-ui">Data de Início</label>
            <input type="date" class="form-control" v-model="customStartDate">
          </div>
          <div class="col-12">
            <label class="dbo-ui">Data de Fim</label>
            <input type="date" class="form-control" v-model="customEndDate">
          </div>
        </div>
        <div class="d-block mt-3">
          <div class="d-flex justify-content-between">
            <button @click="$bvModal.hide('show-modal-dashboard')" class="btn btn-secondary btn-lg px-4 mr-1">
              <i class="fa fa-times mr-1"></i> Cancelar
            </button>
            <button type="button" class="btn btn-primary btn-lg text-uppercase px-4" @click="applyCustomPeriod">
              <b-spinner v-if="loading" small></b-spinner> Aplicar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import policyService from "@/services/policy";
import numericFormatterMixin from '@/mixins/numericFormatter';
import commomsHelper from "@/mixins/commomsHelper";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";

export default {
  name: "DashboardPolicyRenewal",
  data() {
    return {
      translateStatusQuotationTypes: translateStatusQuotationTypes,
      selectedPeriod: 120,
      accumulated_value: 0,
      top_premiums: [],
      customStartDate: '',
      customEndDate: '',
      loading: false,
      cardColorsTags: {
        1: "#ea712d",
        2: "#f18830",
        15: "#f89f32",
        18: "#ffb635",
        16: "#20c997",
        9: "crimson",
      },
    };
  },
  mixins: [
    numericFormatterMixin,
    commomsHelper,
  ],
  created() {
    this.getDashboardPoliciesDue(120, -5);
  },
  methods: {
    setPeriod(days) {
      this.selectedPeriod = days;
      this.getDashboardPoliciesDue(days, -5);
    },
    getDashboardPoliciesDue(due_days = 0, start_days = 0) {
      this.$emit('update-loading', true)
      policyService
        .getDashboardPoliciesDue({
          due_days: due_days,
          start_days: start_days,
          custom_start_date: this.customStartDate,
          custom_end_date: this.customEndDate
        })
        .then((response) => {
          this.top_premiums = response.data.policies;
          this.accumulated_value = response.data.accumulated_value;
        })
        .catch(() => {
          this.$emit('show-error', 'Não foi possível recuperar as apólices a vencer.');
        }).finally(() => {
          this.$emit('update-loading', false)
        });
    },
    applyCustomPeriod() {
      this.setPeriod(this.selectedPeriod);
      this.$refs.modalDashboard.hide();
    },
    getStatusClass(status) {
      const statusClasses = {
        'UPCOMING': 'status-orange',
        'CONTACT': 'status-orange-66',
        'AWAITING': 'status-orange-33',
        'AWAITING_APPROVAL': 'status-yellow',
        'FINISHED': 'status-green',
        'CANCELED': 'status-red'
      };
      return statusClasses[status] || 'status-default';
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
    showModalDashboard() {
      this.$refs.modalDashboard.show();
    },
    getNameColumn(column) {
      switch (column) {
        case 1:
          return 'Renovação próxima';
        case 2:
          return 'Aguardando contato';
        case 15:
          return 'Aguardando doc';
        default:
          return this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, column);
      }
    },
    getPolicyStatus(policy) {
      return policy.renovation_id ?  policy.renovation_id : policy.status;
    }
  }
};
</script>

<style scoped></style>
