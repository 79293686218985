<template>
  <div class="page-container">
    <div class="page-content">
      <div class="page-header">
        <div class="row space-around mb-3">
          <div class="col-10">
            <h1 class="text-white">Sinistros</h1>
          </div>
        </div>
      </div>

      <div v-if="alertMessage" class="col-12">
        <div class="alert-custom alert-danger-custom">{{ alertMessage }}</div>
      </div>

      <div class="page-body">
        <div class="card">
          <div class="card-body p-0">
            <div class="table-responsive shadow overflow-x-auto">
              <b-table
                class="table pretty-title-gray mb-0"
                :items="items"
                :fields="fields"
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
              >
              </b-table>
            </div>
            <nav aria-label="Paginação" class="m-3">
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import policyService from "@/services/policy";
import dateFormatter from "@/mixins/dateFormatter";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import commomsHelper from "@/mixins/commomsHelper";

export default {
  name: "list-claims",
  mixins: [dateFormatter, commomsHelper],
  data: () => ({
    perPage: 10,
    currentPage: 1,
    translateInsuranceTypes: translateInsuranceTypes,
    fields: [
      { key: "number", label: "Apólice", tdClass: "column-size-share",  thClass: "table-pretty dbo-ui rounded-top", },
      { key: "description", label: "Descrição", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "insurance_type_id", label: "Tipo de Seguro", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "insurer_selected", label: "Seguradora", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "insured_name", label: "Segurado", tdClass: "font-14", thClass: "table-pretty dbo-ui", },
      { key: "user_name", label: "Usuário", tdClass: "font-14",thClass: "table-pretty dbo-ui", },
      { key: "created_at", label: "Data de criação", tdClass: "font-14", thClass: "table-pretty dbo-ui rounded-top", },
    ],
    items: [],
    filter: null,
  }),
  created() {
    this.getClaims();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    getClaims() {
      this.resetAlert();
      policyService
        .getClaims()
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
          this.formatList(response.data);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar os sinistros.");
        });
    },
    formatList(data) {
      this.items = data.map((element) => ({
        number: element.policy.number,
        insurance_type_id: this.getEnumKeyByEnumValue(
          this.translateInsuranceTypes,
          element.policy.insurance_type_id
        ),
        description: element.description,
        insurer_selected: element.policy.insurer_selected,
        insured_name: element.policy.insured_name,
        user_name: element.user ? element.user.name : '',
        created_at: this.formatDateTimeLocal(element.created_at),
      }));
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
  },
};
</script>

<style>
</style>
