<template>
  <div class="page-container-renewal">
    <div v-if="alertMessage" class="col-12">
      <b-alert show :variant="alertVariant">{{ alertMessage }}</b-alert>
    </div>
    <div v-if="loading" class="overlay d-flex align-items-center justify-content-center">
      <div class="d-flex justify-content-center mb-2">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
    <div class="page-content-renewal">
      <div class="page-header">
        <div class="row space-around mb-3">
          <div class="col-12 col-md-8 order-1 order-md-1">
            <h1 class="text-white ml-4"><i class="mdi mdi-calendar-clock"></i> Renovação de Apólices</h1>
          </div>
          <div class="col-12 order-3 d-md-none mt-2 ml-4">
            <div class="d-flex flex-column align-items-start w-75">
              <button class="btn btn-outline-white btn-lg mb-2 w-100" @click="openFilterModal()">
                <i class="fas fa-filter"></i> Filtros
              </button>
              <button class="btn btn-outline-white btn-lg mb-2 w-100" @click="clearFilters()">
                <i class="fas fa-times"></i> Limpar
              </button>
              <b-dropdown variant="outline-white" toggle-class="btn btn-outline-white btn-lg custom-dropdown w-100" no-caret>
                <template #button-content>
                  <span><i v-bind:class="getIcon(currentMode)"></i> Modo {{ currentMode }}</span>
                </template>
                <b-dropdown-item @click="setViewMode('listagem')">
                  <i class="fas fa-list mr-2"></i> Modo Listagem
                </b-dropdown-item>
                <b-dropdown-item @click="setViewMode('painel')">
                  <i class="mdi mdi-file-table-box-multiple-outline mr-2"></i> Modo Painel
                </b-dropdown-item>
                <b-dropdown-item @click="setViewMode('dashboard')">
                  <i class="mdi fa-fw mdi-chart-arc mr-1"></i> Modo Dashboard
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div class="col-md-4 text-right pr-4 order-2 d-none d-md-block">
            <div class="button-group button-group-renewal mr-2" v-if="currentMode === 'listagem' || currentMode === 'painel'">
              <button class="btn btn-outline-white mt-1" @click="openFilterModal()" style="border-radius: 5px 0px 0px 5px;">
                <i class="fas fa-filter"></i> Filtros
              </button>
              <button class="btn btn-outline-white mt-1" @click="clearFilters()" style="border-radius: 0px 5px 5px 0px;">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <b-dropdown variant="outline-white" right toggle-class="btn btn-outline-white custom-dropdown" no-caret class="mr-2 mb-1">
              <template #button-content>
                <span><i v-bind:class="getIcon(currentMode)"></i>Modo {{ currentMode }}</span>
              </template>
              <b-dropdown-item @click="setViewMode('listagem')">
                <i class="fas fa-list mr-2"></i> Modo Listagem
              </b-dropdown-item>
              <b-dropdown-item @click="setViewMode('painel')">
                <i class="mdi mdi-file-table-box-multiple-outline mr-2"></i> Modo Painel
              </b-dropdown-item>
              <b-dropdown-item @click="setViewMode('dashboard')">
                <i class="mdi fa-fw mdi-chart-arc mr-1"></i> Modo Dashboard
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      <div class="page-body">
        <div class="card-body p-0">
          <KanbanPolicyRenewal v-if="currentMode === 'painel'"
            :items-policies="itemsPolicies"
            :items-policy-renovation="itemsPolicyRenovation"
            :pages="pages" @get-policy-due="getPoliciesDue"
            @get-policies-renovations="getPoliciesRenovations"
            @update-kanban="updateKanban"
            @show-error="showError"
            ref="painel"/>
          <ListPolicyRenewal
            v-else-if="currentMode === 'listagem'"
            @update-loading="setLoading" 
            @create-renovation="createRenovation"
            @show-error="showError"
            ref="listagem"/>
          <DashboardPolicyRenewal 
            v-else-if="currentMode === 'dashboard'"  
            @update-loading="setLoading" 
            @show-error="showError"/>
        </div>
      </div>

      <div v-if="!isKanbanView && currentMode !== 'dashboard'" class="scroll-padding"></div>
    </div>

    <b-modal id="filter-modal" ref="filterModal" size="md" hide-footer centered>
      <template #modal-title>Filtros</template>
      <div class="form-group">
        <label for="filterPolicyHolder">Tomador</label>
        <b-form-input v-model="filters.policy_holder"></b-form-input>
      </div>

      <div class="form-group">
        <label for="filterInsured">Segurado</label>
        <b-form-input v-model="filters.insured"></b-form-input>
      </div>

      <div class="form-group">
        <label for="filterPolicyNumber">Número da apólice</label>
        <b-form-input v-model="filters.policy_number"></b-form-input>
      </div>

      <div class="d-block mt-3">
        <div class="d-flex justify-content-between">
          <b-button variant="outline-secondary" @click="$bvModal.hide('filter-modal')">Cancelar</b-button>
          <b-button variant="primary" @click="applyFilters()">Aplicar</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import KanbanPolicyRenewal from './RenewPolicy/KanbanPolicyRenewal.vue';
import ListPolicyRenewal from './RenewPolicy/ListPolicyRenewal.vue';
import DashboardPolicyRenewal from './RenewPolicy/DashboardPolicyRenewal.vue';
import policyService from "@/services/policy";
import policyRenovationService from "@/services/policy-renovation";

export default {
  name: "BasePolicyRenewal",
  components: {
    KanbanPolicyRenewal,
    ListPolicyRenewal,
    DashboardPolicyRenewal
  },
  data() {
    return {
      currentMode: 'painel',
      itemsPolicies: [],
      itemsPolicyRenovation: [],
      isKanbanView: true,
      loading: false,
      requestCounter: 0,
      pages: {
        1: { current_page: 1, last_page: 1 },
        2: { current_page: 1, last_page: 1 },
        15: { current_page: 1, last_page: 1 },
        16: { current_page: 1, last_page: 1 },
        18: { current_page: 1, last_page: 1 },
        9: { current_page: 1, last_page: 1 },
      },
      filters: {
        policy_holder: '',
        insured: '',
        policy_number: ''
      },
    };
  },
  created() {
    this.updateKanban();
  },
  methods: {
    setViewMode(mode) {
      this.currentMode = mode;
    },
    applyFilters() {
      if (this.currentMode === 'listagem' && this.$refs.listagem) {
        this.$refs.listagem.currentPage = 1;
        this.$refs.listagem.getPoliciesRenovationsList(
          this.filters.policy_holder,
          this.filters.insured,
          this.filters.policy_number,
          true
        );
    } else {
      this.updateKanban();
    }
      this.$bvModal.hide('filter-modal');
    },
    openFilterModal() {
      this.$bvModal.show('filter-modal');
    },
    clearFilters() {
      this.filters.policy_holder = '';
      this.filters.insured = '';
      this.filters.policy_number = '';
      if (this.currentMode === 'listagem' && this.$refs.listagem) {
        this.$refs.listagem.currentPage = 1;
        this.$refs.listagem.getPoliciesRenovationsList(
          this.filters.policy_holder,
          this.filters.insured,
          this.filters.policy_number,
          true,
        );
      } else {
        this.updateKanban();
      }
    },
    updateKanban() {
      this.getPoliciesDue(60, -5);
      this.getPoliciesDue(120, 60);
      this.getPoliciesRenovations(15);
      this.getPoliciesRenovations(18);
      this.getPoliciesRenovations(16);
      this.getPoliciesRenovations(9);
    },
    checkIfAllRequestsComplete() {
      this.requestCounter++;
      if (this.requestCounter === 6 && this.currentMode == 'listagem') {
        this.$refs.listagem.allPolicies;
        this.$refs.listagem.totalPolicies;
        this.$refs.listagem.paginatedPolicies;
        this.requestCounter = 0;
      }
    },
    getPoliciesDue(due_days = 0, start_days = 0, page=1) {
      page != 1 ? '' : this.loading = true;
      policyService.getPoliciesDue({
        due_days,
        start_days,
        no_renewals: true,
        policy_holder: this.filters.policy_holder,
        insured: this.filters.insured,
        policy_number: this.filters.policy_number,
        view_mode: this.currentMode,
        page: page,
      })
        .then(response => {
          if (!response.data.error) {
            const column = (due_days > 60) ? 1 : 2;
            if(this.currentMode == 'painel') {
              if (page != 1) {
                this.$refs.painel.setloadingColum(column);
                let data = this.itemsPolicies[column];
                let merged = data.concat(response.data.data);
                this.$set(this.itemsPolicies, column, merged);
              } else {
                this.loading = true;
                this.$set(this.itemsPolicies, column, response.data.data);
              }
            }else if(this.currentMode == 'listagem') {
              this.$set(this.itemsPolicies, column, response.data);
            }

            this.pages[column].last_page = response.data.last_page;
            this.pages[column].current_page = response.data.current_page;
          }
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as apólices a vencer.");
        })
        .finally(() => {
          this.loading = false;
          this.checkIfAllRequestsComplete();
        });
    },
    getPoliciesRenovations(status, page = 1) {
      page != 1 ? '' : this.loading = true;
      policyRenovationService.get({
        status_id: status,
        policy_holder: this.filters.policy_holder,
        insured: this.filters.insured,
        policy_number: this.filters.policy_number,
        view_mode: this.currentMode,
        page: page,
      })
      .then(response => {
        if (!response.data.error) {
          if(this.currentMode == 'painel') {
            if (page != 1) {
              this.$refs.painel.setloadingColum(status);
              let data = this.itemsPolicyRenovation[status];
              let merged = data.concat(response.data.data);
              this.$set(this.itemsPolicyRenovation, status, merged);
            } else {
              this.loading = true;
              this.$set(this.itemsPolicyRenovation, status, response.data.data);
            }
          }else if(this.currentMode == 'listagem') {
            this.$set(this.itemsPolicyRenovation, status, response.data);
          }

          this.pages[status].last_page = response.data.last_page;
          this.pages[status].current_page = response.data.current_page;
        }
      })
      .catch(() => {
        this.showError("Não foi possível recuperar as renovações.");
      })
      .finally(() => {
        this.loading = false;
        this.checkIfAllRequestsComplete();
      });
    },
    getIcon(currentMode) {
      if(currentMode === 'listagem') {
        return 'fas fa-list mr-1';
      }
      if(currentMode === 'painel') {
        return 'mdi mdi-file-table-box-multiple-outline mr-2';
      }
      return 'mdi fa-fw mdi-chart-arc mr-1';
    },
    setLoading(status) {
      this.loading = status;
    },
    createRenovation(policyRenewSelected) {
      this.loading_create = true;
      if (!policyRenewSelected) {
        this.showError('Não foi possível iniciar a renovação.');
        return;
      }
      policyRenovationService
        .store(policyRenewSelected.id)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }

          this.redirect('garantee-data', {
            quotationId: response.data.quotation_id,
          })
        })
        .catch(() => {
          this.showError('Não foi possível renovar a apólice.');
        })
        .finally(() => {
          policyRenewSelected = {};
          this.loading_create = false;
          this.$refs.modalCreate.hide();
        });
    },
    showError(message) {
      this.$bvToast.toast(message, {
        title: 'Erro',
        variant: 'danger_toast',
        solid: true,
        toastClass: 'custom-toast',
        bodyClass: 'custom-toast-body',
        appendToast: true,
        autoHideDelay: 5000,
      });
    }
  }
};
</script>
<style scoped>
</style>
