<template>
  <div class="row mt-3" v-if="filteredRenovations.length">
    <div class="col-12 mb-3">
      <h5>
        <div class="form-subheader"><span>Renovações</span></div>
      </h5>
    </div>

    <div class="col-12 mb-3">

      <table class="pretty admin mb-3 shadow">
        <thead>
          <tr>
            <th>Apólice</th>
            <th>Status</th>
            <th>Data/Hora</th>
            <th>Usuário</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="policyRenovation in filteredRenovations" :key="policyRenovation.id">
            <td>
              <a class="btn" @click="redirectQuotation(policyRenovation)">
                {{ policyRenovation.policy_origin.policy_holder_document }} - {{ policyRenovation.policy_origin.policy_holder_name }}
              </a>
            </td>
            <td>{{ policyRenovation.status.translate }}</td>
            <td>{{ formatDateTimeFromISO(policyRenovation.created_at) }}</td>
            <td>{{ policyRenovation.user ? policyRenovation.user.name : '' }}</td>
          </tr>
        </tbody>
      </table>

    </div>

  </div>
</template>

<script>
import dateFormatterMixin from "@/mixins/dateFormatter";

export default {
  name: "RenovationsData",
  props: {
    policy: {
      type: Object,
      required: true
    }
  },
  mixins: [
    dateFormatterMixin,
  ],
  computed: {
    filteredRenovations() {
      return this.policy.renovations.filter(policyRenovation => {
        return policyRenovation.policy_id ||
          (policyRenovation.quotation.policies &&
            policyRenovation.quotation.policies[0] &&
            policyRenovation.quotation.policies[0].id);
      });
    }
  },

  methods: {
    redirectQuotation(policyRenovation) {
      const policy_id = policyRenovation.policy_id ||
                        (policyRenovation.quotation.policies &&
                         policyRenovation.quotation.policies[0] &&
                         policyRenovation.quotation.policies[0].id);
      this.redirect('details-policy', {
        policyId: policy_id,
      });
    },
    redirect(routeName, params = null) {
      this.$router.push({ name: routeName, params: params });
    },
  }
};
</script>

<style></style>
