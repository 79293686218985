<template>
  <div class="page-container-quotation">
    <div class="page-content">
      <div class="page-header">
      <div v-if="loading_page" class="overlay d-flex align-items-center justify-content-center">
        <div>
          <div class="d-flex justify-content-center mb-2">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </div>
      </div>
        <div class="d-flex align-items-center pb-4">
          <div class="col-6">
            <h1 class="text-white"><i class="mdi mdi-file-table-box-multiple-outline"></i> Painel de Cotações</h1>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <b-dropdown variant="outline-white" right toggle-class="btn btn-outline-white custom-dropdown" no-caret>
              <template #button-content>
                <span><i v-bind:class="getIcon(current_mode)"></i>Modo {{ current_mode }}</span>
              </template>
              <b-dropdown-item @click="setViewMode('listagem')">
                <i class="fas fa-list mr-2"></i> Modo Listagem
              </b-dropdown-item>
              <b-dropdown-item @click="setViewMode('painel')">
                <i class="mdi mdi-file-table-box-multiple-outline mr-2"></i> Modo Painel
              </b-dropdown-item>
            </b-dropdown>
            <button class="btn btn-outline-white ml-2" v-b-modal.modal-filter>
              <i class="fa fa-filter mr-2"></i>
              <span class="d-none d-md-inline">Filtros</span>
            </button>
            <button class="btn btn-outline-white" @click="cleanFilter">
              <i class="fa fa-times mr-2"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="page-body-quotation overflow-auto h-100">
        <div class="card-body p-0">
          <div class="kanban-board-quotation d-flex flex-row">
            <div
              class="col col-kanban-quotation"
              v-for="(column, index) in columnsKanban()"
              :key="index"
            >
              <div class="card shadow mb-3 border-card">
                <div
                  :class="cardColors[column]"
                  class="kanban-list border-kanban-title card-header border-bottom px-3 text-uppercase text-center font-weight-bold"
                >
                  <div class="kanban-list-header-quotation">
                    <div class="kanban-list-title-quotation">
                      {{ getEnumKeyByEnumValue(translateStatusQuotationTypes, column) }}
                    </div>
                    <div class="float-right kanban-list-total-cards mt-2">
                      <i class="fa fa-copy"></i>
                      <span class="ml-1">{{ cardCount(column) }}</span>
                    </div>
                  </div>
                </div>

                <div
                  class="card-body p-3 fade-in kanban-list-cards-quotation"
                  :class="{ 'scroll-disabled': loadingColumns[column] }"
                  @scroll="onScroll(column)"
                  :ref="`kanbanListCards${column}`"
                >
                  <draggable
                    :list="items[column]"
                    :group="{ name: 'kanban', pull: true, put: true }"
                    :move="onCardMove"
                    :data-list-id="column"
                    ghost-class="dragging-shadow"
                    class="data-list-limit"
                    @start="onCardGet($event)"
                    @end="onCardDrop($event)"
                  >
                    <div v-for="(quotation, index) in items[column]" :key="index"  class="quotation-card">
                      <div class="kanban-card quotation-card" v-if="quotation">
                        <div class="kanban-card-content-quotationt">
                          <div class="pointer mr-1" style="flex: 1;">
                            <strong>{{ quotation.name ? truncateText(quotation.name) : '---' }}</strong>
                            <br />
                            <span class="fs-6">{{ quotation.insured &&  quotation.insured.name ? truncateText(quotation.insured.name) : '---' }}</span>
                            <br />
                            <span class="fs-6">{{ getEnumKeyByEnumValue(insuranceCategoryTecnicTypes, quotation.insurance_category_id) }}</span>
                            <br />
                            <div class="pill-label-kanban pill-label-quotation">
                              <span class="label"> Prêmio </span>
                              <div class="value" v-b-tooltip.hover>{{ formatCurrency(quotation.guarantee ? quotation.guarantee.contract_value : 0) }}</div>
                            </div>
                          </div>
                        </div>
                        <div 
                          v-bind:class="cardColors[column]" 
                          class="status-bar"
                          :style="{ backgroundColor: cardColorsTags[column] }">
                        </div>
                        <div class="button-container-quotation">
                            <span
                              @click="$can('quotation-show') ? redirect('details-quotation', quotation.id) : null"
                              data-toggle="tooltip"
                              title="Visualizar cotação"
                            >
                              <button type="button" class="button btn btn-sm btn-link text-secondary mt-4">
                                <i class="fa fa-eye"></i>
                              </button>
                            </span>
                            <span
                              v-show="quotation.status != statusQuotationTypes.ISSUED && $can.any(['quotation-edit', 'quotation-update'])"
                              @click="showModal(quotation)"
                              data-toggle="tooltip"
                              title="Alterar status"
                            >
                              <button type="button" class="button btn btn-sm btn-link text-secondary">
                                <i class="fa fa-pen"></i>
                              </button>
                            </span>
                          </div>
                      </div>
                    </div>
                  </draggable>
                  <div v-if="loadingColumns[column]" class="loading-quotation-column">
                    <b-spinner small label="Carregando..."></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="show"
      id="modal-cotacao-status"
      size="lg"
      @hidden="onCloseModal"
    >
      <template #modal-title>
        <div>
          <div>
            <h5 class="modal-title" id="modal-cotacao-status-label">
              Status da cotação
            </h5>
            <p class="text-muted mb-0">Revise e altere o status da cotação</p>
          </div>
        </div>
      </template>
      <div v-if="element" class="modal-lg">
        <div class="p-3 pb-0">
          <div>
            <div class="row form">
              <div class="col-12 col-lg-4">
                <label class="dbo-ui" for="">Tipo de seguro</label>
                <p>
                  {{
                    getEnumKeyByEnumValue(
                      translateInsuranceTypes,
                      element.insurance_type_id
                    )
                  }}
                </p>
              </div>
              <div class="col-12 col-lg-4">
                <label class="dbo-ui" for="">Cliente</label>
                <p>{{ element.insured ? element.insured.name : "---" }}</p>
              </div>
              <div class="col-12 col-lg-4">
                <label class="dbo-ui" for="">Valor</label>
                <p>{{ formatCurrency(element.guarantee ? element.guarantee.contract_value : 0)}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12 pb-4" v-if="isMaster()">
        <label class="dbo-ui" for="">Status</label>
        <select class="custom-select" v-model="dataForm.status">
          <option value="null">Selecione...</option>
          <option
            v-for="(status, index) in options"
            :key="index"
            :value="status.value"
          >
            {{ status.text }}
          </option>
        </select>
      </div>
      <template #modal-footer>
        <div>
          <button
            :disabled="disabledButtonChange"
            @click="changeStatusQuotation(element)"
            type="button"
            class="btn btn-primary"
          >
            <b-spinner v-if="loading" small></b-spinner> Alterar
          </button>
        </div>
      </template>
    </b-modal>

    <div class="modal">
      <b-modal
        ref="modalFilter"
        id="modal-filter"
        size="lg"
        class="mt-4"
        centered
      >
        <br />
        <template class="text-align-center" #modal-title>
          Filtrar Cotações
        </template>

        <form class="mb-2">
          <label>Tomador</label>
          <b-form-input
            class="mb-3"
            v-model="policy_holder"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <label>Segurado</label>
          <b-form-input
            v-model="insured"
            class="mb-3"
            size="lg"
            id="name-input"
            required
          ></b-form-input>

          <label>Documento <small>(Apenas Números)</small></label>
          <b-form-input
            type="number"
            v-model="document"
            class="mb-3"
            size="lg"
            id="name-input"
            required
            v-on:keypress="isNumber($event)"
          ></b-form-input>
        </form>

        <template #modal-footer>
          <b-button @click="filterQuotations()" variant="outline-primary"
            >Filtrar <i class="fa fa-search"></i
          ></b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import quotationService from "@/services/quotation";
import commomsHelper from "@/mixins/commomsHelper";
import numericFormatterMixin from "@/mixins/numericFormatter";
import authenticationService from '@/services/authentication';
import draggable from 'vuedraggable';
import statusQuotationTypes from "@/enums/statusQuotationTypes";
import translateInsuranceTypes from "@/enums/translate/insuranceTypes";
import translateStatusQuotationTypes from "@/enums/translate/statusQuotationTypes";
import insuranceCategoryTecnicTypes from "@/enums/translate/insuranceCategoryTecnicTypes";

export default {
  name: "My-Quotation",
  components: { draggable },
  mixins: [numericFormatterMixin, commomsHelper],
  data: () => ({
    insuranceCategoryTecnicTypes: insuranceCategoryTecnicTypes,
    translateStatusQuotationTypes,
    translateInsuranceTypes,
    statusQuotationTypes,
    show: false,
    loading: false,
    loading_page: false,
    element: null,
    dataForm: { status: null },
    options: [],
    items: {}, 
    perPage: 10, 
    currentPage: { 
      3: 1,
      4: 1,
      5: 1,
      6: 1,
      7: 1
    },
    hasMorePages: { 
      3: true,
      4: true,
      5: true,
      6: true,
      7: true
    },
    loadingColumns: { 
      3: false,
      4: false,
      5: false,
      6: false,
      7: false
    },
    cardColors: {
      3: "kanban-list-status-yellow",
      4: "kanban-list-status-green",
      5: "kanban-list-status-green", 
      6: "kanban-list-status-red", 
      7: "kanban-list-status-red", 
    },
    cardColorsTags: {
      3: "#ffb635",
      4: "#20c997",
      5: "#20c997",
      6: "crimson",
      7: "crimson",
    },
    policy_holder: "",
    insured: "",
    status: "",
    start_created: "",
    end_created: "",
    document: "",
    current_mode: 'painel',
    to_column: null,
    from_column: null,
    original_index: null,
    quotation_selected: null,
    new_index: null
  }),
  computed: {
    disabledButtonChange() {
      return this.dataForm.status ? false : true;
    },
    cardCount() {
      return (column) => {
        return this.items[column] ? this.items[column].length : 0;
      };
    }
  },
  created() {
    this.loadAllColumns();
    this.getStatusKanban();
  },
  methods: {
    loadAllColumns() {
      this.loading_page = true;
      [3, 4, 5, 6, 7].forEach((column) => {
        this.getQuotations(column); 
      });
    },
    columnsKanban() {
      return [3, 4, 5, 6, 7];
    },
    changeStatusQuotation(element) {
      this.loading = true;
      this.resetAlert();
      quotationService
        .updateStatusQuotation(element.id, this.dataForm)
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }
        })
        .catch(() => {
          this.showError("Não foi possível mudar o status da cotação.");
        })
        .finally(() => {
          this.show = false;
          this.loading = false;
          this.clearColumns();
          this.loadAllColumns();
        });
    },
    redirect(routeName, id) {
      this.$router.push({ name: routeName, params: { quotationId: id } });
    },
    getQuotations(column) {
      if (!this.hasMorePages[column] || this.loadingColumns[column]) return; 

      this.loadingColumns[column] = true;
      this.resetAlert();
      const currentPage = this.currentPage[column];
      quotationService
        .getKanbanData(this.formatFilters(column, currentPage))
        .then((response) => {
          if (response.data.error) {
            this.showError(response.data.message);
            return;
          }

          const fetchedData = response.data.data || [];

          if (fetchedData.length < this.perPage) {
            this.hasMorePages[column] = false;
          }

          const existingItems = this.items[column] || [];
          this.$set(this.items, column, [...existingItems, ...fetchedData]);
        })
        .catch(() => {
          this.showError("Não foi possível recuperar as cotações.");
        })
        .finally(() => {
          this.loadingColumns[column] = false;
          this.loading_page = false;
        });
    },
    formatFilters(column, page) {
      return {
        policy_holder: this.policy_holder,
        insured: this.insured,
        document: this.document,
        page,
        per_page: this.perPage,
        type: column
      };
    },
    getIcon(currentMode) {
      if (currentMode === 'listagem') {
        return 'fas fa-list mr-1';
      }
      if (currentMode === 'painel') {
        return 'mdi mdi-file-table-box-multiple-outline mr-2';
      }
      return 'mdi fa-fw mdi-chart-arc mr-1';
    },
    onScroll(column) {
      const containerRef = this.$refs[`kanbanListCards${column}`];
      if (containerRef && containerRef.length > 0) {
        const container = containerRef[0];
        if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
          this.loadMoreCards(column);
        }
      }
    },
    loadMoreCards(column) {
      if (this.hasMorePages[column] && !this.loadingColumns[column]) {
        this.currentPage[column] += 1;
        this.getQuotations(column);
      }
    },
    showModal(element, status = null) {
      this.element = element;
      this.dataForm.status = status;
      this.show = true;
    },
    filterQuotations() {
      this.clearColumns();
      this.loadAllColumns();
      this.$refs.modalFilter.hide();
    },
    cleanFilter() {
      this.policy_holder = "";
      this.insured = "";
      this.document = "";
      this.clearColumns();
      this.loadAllColumns();
    },
    clearColumns() {
      Object.keys(this.items).forEach(column => {
        this.$set(this.items, column, []);
      });
      Object.keys(this.currentPage).forEach(column => {
        this.currentPage[column] = 1;
        this.hasMorePages[column] = true;
      });
    },
    setViewMode(mode) {
      this.current_mode = mode;
      if(mode == 'listagem') {
        this.redirect('list-quotation');
      }
    },
    isMaster() {
      return authenticationService.isMaster();
    },
    truncateText(text, maxChar = 28) {
      let length = text.length;
      return length > maxChar ? `${text.substring(0, maxChar)}...` : text;
    },
    getStatusKanban() {
      this.listStatusKanbanQuotation()
        .forEach((element) => {
          this.options.push({value: element, text: this.getEnumKeyByEnumValue(this.translateStatusQuotationTypes, element)})
        })
    },
    onCardGet(event) {
      this.from_column = event.from.getAttribute('data-list-id');
      this.original_index = event.oldIndex;
      this.quotation_selected = this.items[this.from_column][this.original_index] ?? null;
    },
    onCardDrop(event) {
      let to_column = event.to.getAttribute('data-list-id');
      this.new_index = event.newIndex;
      if (this.from_column == 5) {
        return;
      }
      if (this.quotation_selected) {
        this.showModal(this.quotation_selected, to_column);
      }
    },
    onCardMove(evt) {
      this.to_column = evt.to.dataset.listId;
      if (evt.from.dataset.listId === '5') {
        evt.from.classList.add('invalid-move');
        setTimeout(() => evt.from.classList.remove('invalid-move'), 1000);
        return false;
      }

      return true;
    },
    onCloseModal() {
      if (this.quotation_selected && this.from_column !== null && this.original_index !== null) {
        const movedCard = this.items[this.to_column].splice(this.new_index, 1)[0];
        this.items[this.from_column].splice(this.original_index, 0, movedCard);

        this.quotation_selected = null;
        this.from_column = null;
        this.original_index = null;
        this.to_column = null;
        this.new_index = null;
      }

      this.element = null;
      this.show = false;
    },
    showError(message) {
        this.$bvToast.toast(message, {
          title: 'Erro',
          variant: 'danger_toast',
          solid: true,
          toastClass: 'custom-toast',
          bodyClass: 'custom-toast-body',
          appendToast: true,
          autoHideDelay: 5000,
        });
      }
  }
};
</script>

<style>
</style>
